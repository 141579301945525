import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaEnvelope } from "react-icons/fa";
import homeLogo from "../../Assets/home-main.png";
import { TbPointFilled } from "react-icons/tb";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home" style={{ paddingBottom: 20 }}>
        <Container className="home-content" style={{ paddingBottom: 20 }}>
          <Row>
            {/* Image column with order-first for mobile */}
            <Col md={5} className="order-md-2 order-1" style={{ paddingBottom: 20, paddingTop: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px", borderRadius: "20px" }}
              />
            </Col>

            {/* Text column with order-second for mobile */}
            <Col md={7} className="home-header order-md-1 order-2">
            <h1 className="project-heading" style={{ 
              textAlign: "left"  // Added this style
            }}>
                Willkommen bei <strong className="main-name"> KABAZ CONSULTING</strong>
              </h1>
              <div style={{ textAlign: "left", padding: "20px 0" }}>
                <h2>Unsere Dienstleistungen</h2>
                <h3>
                <ul className="service-list" style={{ 
                  listStyle: "none", 
                  textAlign: "left",
                }}>
                  <li style={{ padding: "8px 0", color: "#ff8408" }}><TbPointFilled /> Agile Transformation</li>
                  <li style={{ padding: "8px 0", color: "#ff8408" }}><TbPointFilled /> Scrum Coaching</li>
                  <li style={{ padding: "8px 0", color: "#ff8408" }}><TbPointFilled /> SAFe Implementierung</li>
                  <li style={{ padding: "8px 0", color: "#ff8408" }}><TbPointFilled /> Change Management</li>
                  <li style={{ padding: "8px 0", color: "#ff8408" }}><TbPointFilled /> Agiles Training</li>
                  <li style={{ padding: "8px 0", color: "#ff8408" }}><TbPointFilled /> Product Owner Coaching</li>
                  <li style={{ padding: "8px 0", color: "#ff8408" }}><TbPointFilled /> Agile Workshops</li>
                </ul>
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Home2 Inhalte direkt in Home integriert */}
      <Container 
        fluid 
        className="home-about-section" 
        id="about" 
        style={{ paddingTop: 0 }}
      >
        <Container>
          <Row>
            <Col md={12} className="home-about-social">
              Lassen Sie uns über Ihre <span className="purple">Herausforderungen </span>sprechen.<br></br>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href="mailto:anfrage@kabaz.consulting?subject=Anfrage&body=Guten%20Tag,%0D%0A%0D%0Aich%20habe%20eine%20Frage%20zu..."
                    className="icon-colour home-social-icons"
                  >
                    <FaEnvelope />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;