import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "left", padding: "10px" }}>
          {/* Image column with order-first for mobile */}
          <Col
            md={5}
            style={{ paddingTop: "0px", paddingBottom: "30px" }}
            className="about-img order-md-2 order-1"
          >
            <img src={laptopImg} alt="about" className="img-fluid" style={{ maxHeight: "450px", borderRadius: "20px" }} />
          </Col>
          
          {/* Text column with order-second for mobile */}
          <Col
            md={7}
            style={{
              justifyContent: "left",
              paddingTop: "0px",
              paddingBottom: "20px",
              textAlign: "left"  // Added this style
            }}
            className="order-md-1 order-2"
          >
            <h1 className="project-heading" style={{ 
              textAlign: "left"  // Added this style
            }}>
              Was steckt hinter <br></br><strong className="purple">Kabaz Consulting</strong>
            </h1>
            <Aboutcard />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;