import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { 
FaLinkedinIn,
FaEnvelope, 
} from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Kabaz Consulting</h3>
        </Col>
        <Col md="4" className="footer-copywright" style={{ textAlign: "center" }}>
          <p style={{ padding: "8px 0" }}>
            © {year} Kabaz Consulting<span style={{ margin: "8 10px" }}> | </span>  
            <a href="/impressum" style={{ color: "white", textDecoration: "none" }}>
              Impressum
            </a>
          </p>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/company/kabaz-consulting/"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="mailto:anfrage@kabaz.consulting?subject=Anfrage&body=Guten%20Tag,%0D%0A%0D%0Aich%20habe%20eine%20Frage%20zu..."
                style={{ color: "white" }}
              >
                <FaEnvelope />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
