import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import bundesdruckerei from "../../Assets/Projects/bundesdruckerei.png";
import vtg from "../../Assets/Projects/vtg.png";
import atlas from "../../Assets/Projects/atlas.png";
import lufthansa from "../../Assets/Projects/lufthansa.png";
import db from "../../Assets/Projects/db.png";
import commerzbank from "../../Assets/Projects/commerzbank.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading" style={{ 
              textAlign: "left"  // Added this style
            }}>
          Unsere abgeschlossenen <strong className="purple">Projekte</strong>
        </h1>
        <Row style={{ justifyContent: "left", paddingBottom: "10px" }}>
          {projectData.map((project, index) => (
            <Col md={6} className="project-card" key={index}>
              <ProjectCard {...project} />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

const projectData = [
  {
    imgPath: bundesdruckerei,
    title: <><strong className="purple">IT-Systemhaus, Sicherheits- und Wertdruck</strong> <br />(2022 - 2025)</>,
    description: (
        <div style={{ textAlign: "left", marginTop: "30px" }}>
        <p><strong>Ziel des Projekts:</strong> Transformation des Service Desks mit Atlassian Jira</p>
        <p>Dieses Projekt stellte eine grundlegende Modernisierung der Service-Infrastruktur dar, indem der veraltete Service Desk durch die leistungsstarke Atlassian Jira Service Management-Plattform ersetzt wurde.</p>
        <p>Statt einer einfachen Ablösung wurde eine strategische Neuausrichtung realisiert, bei der die bewährten Kernfunktionen des bisherigen Systems übernommen und durch maßgeschneiderte Anpassungen erheblich verbessert wurden. Die Integration wurde präzise auf die spezifischen betrieblichen Anforderungen zugeschnitten, um maximale Effizienz zu gewährleisten.</p>
        <p>Ein kritischer Erfolgsaspekt war die nahtlose Migration der Stammdaten aus dem Legacy-System in die neue ITSM-Umgebung, wodurch wertvolles Organisationswissen bewahrt und gleichzeitig die Vorteile der fortschrittlichen Jira-Funktionalitäten genutzt werden konnten. Diese Transformation ermöglichte eine erhebliche Steigerung der Servicequalität, Transparenz und Reaktionsgeschwindigkeit.</p>
        </div>
    )
  },
  {
    imgPath: vtg,
    title: <><strong className="purple">Schienengüterverkehr, Logistik</strong> <br />(2021 - 2022)</>,
    description: (
      <div style={{ textAlign: "left", marginTop: "30px" }}>
        <p><strong>Ziel des Projekts:</strong> Digitale Revolution im Schienengüterverkehr</p>
        <p>Dieses ambitionierte Projekt fokussierte sich auf die transformative Weiterentwicklung einer bahnbrechenden Plattform, die den Schienengüterverkehr neu definiert. Das zentrale Ziel war es, die komplexe Welt der Schienengüterverkehrsangebote durch smarte Digitalisierung radikal zu vereinfachen und zugänglicher zu machen.</p>
        <p>Die Plattform bricht Silos auf und schafft ein digitales Ökosystem, in dem Kunden, Partner und Lieferanten nahtlos zusammenfinden und interagieren können.</p>
        <p>Durch die Integration übergreifender digitaler Produkte revolutioniert traigo.com die Art und Weise, wie Schienengüterverkehrsleistungen angeboten, gebucht und abgewickelt werden. Die Plattform eliminiert Komplexität und schafft intuitive Zugangspunkte zu einem traditionell undurchsichtigen Markt – was Zeit spart, Kosten reduziert und die Effizienz in der gesamten Lieferkette steigert.</p>
        <p>Letztendlich dient dieses Projekt als Katalysator für eine nachhaltigere Logistikzukunft, indem es den umweltfreundlichen Schienengüterverkehr durch digitale Innovation wettbewerbsfähiger und attraktiver macht.</p>
        </div>
    )
  },
  {
    imgPath: atlas,
    title: <><strong className="purple">Verteidigung, Marine-Technologie</strong> <br />(2020 - 2021)</>,
    description: (
      <div style={{ textAlign: "left", marginTop: "30px" }}>
        <p><strong>Ziel des Projekts:</strong> Integrierte Kommunikationslösung für maritime Streitkräfte</p>
        <p>Das Projekt umfasst die Entwicklung einer innovativen Kommunikationsanwendung für den Einsatz bei See- und Unterwasserstreitkräften. Diese maßgeschneiderte Softwarelösung optimiert die systemübergreifende Kommunikation.</p>
        <p>Die Anwendung zeichnet sich durch folgende Kernfunktionen aus:</p>
        <ul>
          <li>Echtzeitübertragung von Systemdaten zwischen allen relevanten Schiffskomponenten</li>
          <li>Kontinuierliches Monitoring des Gesamtsystemstatus mit übersichtlicher Visualisierung</li>
          <li>Automatisierte Alarmierung bei Abweichungen oder kritischen Zuständen</li>
          <li>Intelligente Lösungsvorschläge und Handlungsempfehlungen bei technischen Störungen</li>
          <li>Nahtlose Integration mit bestehenden Schiffssystemen</li>
        </ul>
        <p>Durch die implementierte Lösung wird die Reaktionsgeschwindigkeit bei technischen Störungen signifikant verbessert, was zu einer erhöhten Einsatzbereitschaft und Sicherheit der maritimen Einheiten führt. Die Anwendung unterstützt dabei sowohl den Routinebetrieb als auch kritische Einsatzszenarien mit höchster Zuverlässigkeit.</p>
        <p>Die Software wurde unter Berücksichtigung strengster Sicherheitsanforderungen und militärischer Standards entwickelt und erfüllt alle Vorgaben für den Einsatz in hochsensiblen Bereichen.</p>
        </div>
    )
  },
  {
    imgPath: lufthansa,
    title: <><strong className="purple">Finanzdienstleistungen, Luftfahrt</strong> <br />(2019 - 2019)</>,
    description: (
      <div style={{ textAlign: "left", marginTop: "30px" }}>
      <p><strong>Ziel des Projekts:</strong> Cloud Transformation: Von Legacy zu Azure</p>
      <p>Dieses strategische Projekt steht im Zentrum der digitalen Zukunft und umfasst die vollständige Migration der bestehenden Legacy IT-Infrastruktur in die Microsoft Azure Cloud. Mit einem durchdachten agilen Ansatz revolutionieren wir nicht nur die technologische Grundlage des Unternehmens, sondern begleiten auch die tiefgreifende organisatorische Transformation.</p>
      <p>Die Migration adressiert mehrere kritische Geschäftsziele gleichzeitig: Steigerung der operativen Agilität, Optimierung der Kostenstruktur und Erschließung neuer digitaler Geschäftsmöglichkeiten. Durch den Übergang zu einer modernen Cloud-Architektur werden Innovationszyklen drastisch verkürzt und die Marktreaktionsfähigkeit signifikant erhöht.</p>
      <p>Besonders bemerkenswert ist unser ganzheitlicher Transformationsansatz, der über die reine Technologiemigration hinausgeht. Wir integrieren Change Management, Kompetenzaufbau und Prozessoptimierung in jede Phase des Projekts. Die agile Methodik ermöglicht dabei eine schrittweise Migration mit kontinuierlichen Wertlieferungen, während das Tagesgeschäft reibungslos weiterläuft.</p>
      <p>Das Ergebnis: Eine zukunftssichere IT-Landschaft, für das Unternehmen als digitalen Vorreiter positioniert und gleichzeitig eine nachhaltige Grundlage für kontinuierliche Innovation schafft.</p>
      </div>
    )
  },
  {
    imgPath: db,
    title: <><strong className="purple">Finanzdienstleistungen, Banking</strong> <br />(2015 - 2018)</>,
    description: (
      <div style={{ textAlign: "left", marginTop: "30px" }}>
      <p><strong>Ziel des Projekts:</strong> Der neue ETF basierte Robi-Advisor</p>
              
      <p>Dies Projekt transformiert die traditionelle Vermögensverwaltung durch die Entwicklung eines hochinnovativen Robo-Advisors, der die Welt der ETF-Investments revolitioniert. Diese digitale Plattform vereint fortschrittliche Finanztechnologie mit einem nutzerorientierten Anlageansatz und macht professionelle Vermögensverwaltung für eine breite Anlegerschaft zugänglich.</p>
      <p>Im Kern der Lösung steht ein sophistiziertes algorithmisches System, das kontinuierlich Marktdaten analysiert und Portfolios in Echtzeit optimiert. Die proprietären Algorithmen bewerten tausende Datenpunkte und passen die Anlagestrategie präzise an individuelle Risikobereitschaft, Anlageziele und Marktbedingungen an.</p>
      <p>Der Robo-Advisor nutzt die Effizienz und Kostenvorteile von ETFs als Anlageinstrumente und kombiniert diese mit dynamischen Allokationsstrategien. Dadurch werden Diversifikation und Renditeoptimierung auf einem Niveau erreicht.</p>
      <p>Besonders bemerkenswert ist die nahtlose Nutzererfahrung: Komplexe Finanzentscheidungen werden durch intuitive Interfaces und transparente Visualisierungen verständlich dargestellt. Die Plattform wächst mit den Bedürfnissen der Nutzer und passt sich kontinuierlich an deren sich verändernde Lebenssituationen an.</p>
      </div>
    )
  },
  {
    imgPath: commerzbank,
    title: <><strong className="purple">Finanzdienstleistungen, Banking</strong> <br />(2013 - 2015)</>,
    description: (
      <div style={{ textAlign: "left", marginTop: "30px" }}>
      <p><strong>Ziel des Projekts:</strong> Modernisierung der Zahlungsverkehrsinfrastruktur: SEPA CAMT-Integration</p>
              
      <p>Das strategische Projekt revolutioniert die Bank-Kunde-Kommunikation durch die nahtlose Integration von SEPA CAMT-Nachrichten in das bestehende Payments Transaction System (PTS). Diese zukunftsweisende Implementation ermöglicht einen vollständig digitalen, standardisierten Informationsaustausch zwischen Finanzinstituten und ihren Kunden.</p>
      <p>Im Zentrum der Lösung steht die Einführung der SEPA-konformen CAMT-Formate (CAMT.052, CAMT.053 und CAMT.054), die als neue Goldstandards für Kontoauszüge, Kontosaldenmitteilungen fungieren. Diese XML-basierten Strukturen ersetzen traditionelle, fragmentierte Kommunikationswege und schaffen eine einheitliche Sprache für den modernen Zahlungsverkehr.</p>
      <p>Besonders bemerkenswert ist die technologische Leistung, bestehende Systeme für die Verarbeitung komplexer XML-Strukturen zu optimieren. Dadurch wird nicht nur die Verarbeitung von SEPA-Zahlungsaufträgen erheblich beschleunigt, sondern auch die Darstellung von Kontoinformationen auf ein neues Niveau gehoben – strukturiert, maschinenlesbar und in Echtzeit verfügbar.</p>
      <p>Die Implementierung schafft signifikante Mehrwerte für alle Beteiligten:</p>
      <ul>
        <li>Drastische Reduzierung manueller Prozesse und Fehlerquellen</li>
        <li>Erhöhte Transparenz und Nachvollziehbarkeit von Transaktionen</li>
        <li>Beschleunigte Verarbeitungszeiten und verbesserte Cash-Management-Fähigkeiten</li>
        <li>Zukunftssichere Compliance mit europäischen Zahlungsverkehrsstandards</li>
      </ul>
      </div>
    )
  },
];

export default Projects;