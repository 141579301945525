import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Impressum() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Impressum
              </h1>

              <h1 className="heading-name">
                <strong className="main-name"> KABAZ CONSULTING</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <p>
                  <strong>Angaben gemäß § 5 TMG:</strong>
                  <br />
                  Kabaz Consulting
                  <br />
                  Oppenheimer Str. 23
                  <br />
                  55130 Mainz
                </p>
                <p>
                  <strong>Vertreten durch:</strong>
                  <br />
                  Hassen Kabaz
                </p>
                <p>
                  <strong>Kontakt:</strong>
                  <br />
                  E-Mail: anfrage @ kabaz.consulting
                </p>
                <p>
                  <strong>Umsatzsteuer-ID:</strong>
                  <br />
                  Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
                  <br />
                  DE319549079
                </p>
                <p>
                  <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong>
                  <br />
                  Kabaz Consulting
                  <br />
                  Oppenheimer Str. 23
                  <br />
                  55130 Mainz
                </p>
                <p>
                  <strong>Haftungsausschluss:</strong>
                  <br />
                  Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf dieser Website nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Sobald uns entsprechende Rechtsverletzungen bekannt werden, entfernen wir diese Inhalte umgehend.
                </p>
                <p>
                  <strong>Haftung für Links:</strong>
                  <br />
                  Unsere Website enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </p>
                <p>
                  <strong>Urheberrecht:</strong>
                  <br />
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf dieser Website unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet und entsprechend gekennzeichnet. Solltest du trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen entfernen wir derartige Inhalte umgehend.
                </p>
                </div>
                <h1 style={{ paddingBottom: 15 }} className="heading">
                Datenschutzerklärung
              </h1>
              <div style={{ padding: 50, textAlign: "left" }}>
                <p>
                  <strong>1. Erhebung und Speicherung personenbezogener Daten sowie deren Nutzung</strong>
                  <br />
                  Beim Zugriff auf unsere Webseite werden automatisch bestimmte Daten in sogenannten Logfiles gespeichert, darunter Ihre IP-Adresse, Browser-Typ, Betriebssystem, Datum und Uhrzeit des Zugriffs.
                  <br />
                  Diese Daten dienen ausschließlich der Sicherstellung eines reibungslosen Betriebs unserer Webseite und werden nicht zur Identifikation Ihrer Person verwendet.
                </p>

                <p>
                  <strong>2. Nutzung unseres Kontaktformulars</strong>
                  <br />
                  Wenn Sie unser Kontaktformular nutzen, speichern wir Ihre E-Mail-Adresse und den Inhalt Ihrer Nachricht zur Bearbeitung Ihrer Anfrage. Diese Daten werden nach Abschluss der Kommunikation gelöscht.
                </p>

                <p>
                  <strong>3. Weitergabe von Daten</strong>
                  <br />
                  Ihre personenbezogenen Daten werden nur weitergegeben, wenn dies zur Vertragserfüllung erforderlich ist oder wenn Sie ausdrücklich eingewilligt haben.
                </p>

                <p>
                  <strong>4. Ihre Rechte</strong>
                  <br />
                  Sie haben das Recht auf Auskunft, Berichtigung, Löschung und Einschränkung der Verarbeitung Ihrer personenbezogenen Daten. Zudem können Sie der Verarbeitung widersprechen und eine Übertragung Ihrer Daten verlangen.
                </p>

                <p>
                  <strong>5. Cookies</strong>
                  <br />
                  Unsere Webseite verwendet ausschließlich essenzielle Cookies, die für den Betrieb der Seite notwendig sind. Sie können diese in den Einstellungen Ihres Browsers verwalten oder blockieren.
                </p>

                <p>
                  <strong>6. Datensicherheit</strong>
                  <br />
                  Ihre Daten werden durch technische und organisatorische Maßnahmen vor unbefugtem Zugriff geschützt. Die Übertragung sensibler Daten erfolgt verschlüsselt über das SSL/TLS-Protokoll.
                </p>

                <p>
                  <strong>7. Kontakt für Datenschutzfragen</strong>
                  <br />
                  Kabaz Consulting
                  <br />
                  Oppenheimer Str. 23
                  <br />
                  55130 Mainz
                  <br />
                  E-Mail: datenschutz@kabaz.consulting
                </p>

                <p>
                  Diese Datenschutzerklärung ist aktuell gültig und hat den Stand März 2025. Änderungen bleiben vorbehalten.
                </p>

              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Impressum;
