import React from "react";
import Card from "react-bootstrap/Card";
import { TbPointFilled } from "react-icons/tb";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" }}>
          <p>Wir sind ein Beratungsunternehmen mit Schwerpunkt auf Coaching, agile Transformation, Change Management und Digitalisierung.</p>
          <p>Mit mehrjähriger Erfahrung begleiten wir Unternehmen durch komplexe Projekte und Veränderungsprozesse. Unsere Expertise beruht auf einem tiefen Verständnis bewährter Methoden wie Scrum, SAFe® und Kanban, die wir optimal kombinieren, um maßgeschneiderte Lösungen zu schaffen.</p>
          <p>Unsere Mission ist es, Organisationen zu befähigen, in einer sich ständig verändernden Geschäftswelt nicht nur zu bestehen, sondern blitzschnell zu reagieren. Wir verstehen, dass jedes Unternehmen einzigartig ist – deshalb arbeiten wir eng mit unseren Kunden zusammen, um ihre spezifischen Herausforderungen zu verstehen und passende Strategien zu entwickeln.</p>
          <p>Was uns auszeichnet, ist unser ganzheitlicher Ansatz: Wir betrachten nicht nur Prozesse und Methoden, sondern auch die Menschen und die Unternehmenskultur. Denn erfolgreiche agile Transformation bedeutet mehr als nur neue Arbeitsweisen einzuführen – es geht um einen fundamentalen Kulturwandel, der von allen Mitarbeitern getragen wird.</p>
          <p>Neben unserer Beratungsleistung bieten wir auch hochwertige Trainings im Bereich agiler Transformation an – insbesondere auf Basis der Scrum-, KanBan- und SAFe® Frameworks. Unsere praxisorientierten Workshops vermitteln nicht nur theoretisches Wissen, sondern befähigen Teilnehmer, das Gelernte sofort in ihrem Arbeitsalltag umzusetzen.</p>
          <p><span className="purple">Unsere Kernkompetenzen:</span></p>
          </p>
          <ul>
            <li className="about-activity">
              <TbPointFilled /> Agile Coaching & Skalierte Agilität (SAFe®, Scrum, Kanban)
            </li>
            <li className="about-activity">
              <TbPointFilled /> Scrum Master & Product Owner Support
            </li>
            <li className="about-activity">
              <TbPointFilled /> Change Management & digitale Transformation
            </li>
            <li className="about-activity">
              <TbPointFilled /> Agile Leadership & Kulturwandel
            </li>
            <li className="about-activity">
              <TbPointFilled /> Aufbau selbstorganisierter Teams
            </li>
            <li className="about-activity">
              <TbPointFilled /> Implementierung kontinuierlicher Verbesserungsprozesse
            </li>
            <li className="about-activity">
              <TbPointFilled /> Business Analyse & Requirements Engineering
            </li>
          </ul>
          <p style={{ textAlign: "left" }}>Vertrauen Sie auf Kabaz Consulting als Ihren Partner auf dem Weg zu mehr Agilität, Effizienz und Innovation. Gemeinsam gestalten wir eine erfolgreiche Zukunft für Ihr Unternehmen.</p>
          <p style={{ color: "#ff8408" }}>
          "Veränderung ist die einzige Konstante – wir machen sie zu Ihrem Vorteil!"{" "} 
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;